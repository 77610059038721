.icon_bar_container {
  bottom: 0px;
  left: 0px;
  width: 80px;
  display: flex;
  flex-direction: column;
  background-color: #374151;
  justify-content: flex-start;
  padding-left: 5px;
  box-shadow: 2px 0px 5px #374151;
}

.icon_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  background-color: beige;
  margin-bottom: 5px;
  transition: all 0.3s ease-out;
}

.icon_box_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  background-color: #ff8042;
  margin-bottom: 5px;
  transition: all 0.3s ease-out;
}

.icon_box:hover {
  background-color: aliceblue;
}

.icon {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #374151;
  transition: all 0.3s ease-in-out;
}

.icon_text {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .icon_bar_container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: beige;
    justify-content: space-between;
    padding: 5px 10px;
    box-shadow: 2px 0px 5px #374151;
  }

  .icon_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    color: #374151;
    cursor: pointer;
    width: 80px;
    border-radius: 5px;
    padding-top: 3px;
  }

  .icon {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    transition: all 0.3s ease-in-out;
  }

  .icon_box_active {
    width: 80px;
    border-radius: 5px;
    padding-top: 3px;
  }

  .icon_text {
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}
