.table_loader {
  background-color: white;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_container {
  width: 99%;
  border: 1px solid #374151;
  overflow: auto;
  padding: 5px;
}

.table {
  width: 100%;
  border-spacing: 0px;
  background-color: white;
  border-bottom: 1px solid #374151;
  border-left: 1px solid #374151;
}

.table_head_row {
  margin-top: 2px;
  background-color: aliceblue;
}

.table_head {
  cursor: pointer;
  border-top: 1px solid #374151;
  padding-left: 5px;
  cursor: default;
}

.table_head_text {
  height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  border-right: 1px solid #374151;
}

.table_row {
  height: 30px;
  margin-top: 2px;
  vertical-align: text-top;
}

.table_row_clickable {
  height: 40px;
  margin-top: 2px;
  cursor: pointer;
}

.table_row_clickable:hover {
  background-color: beige;
}

.cell {
  border-top: 1px solid #374151;
  border-right: 1px solid #374151;
  padding: 5px;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 400;
  min-width: 50px;
}

.cell_x_small {
  border-top: 1px solid #374151;
  border-right: 1px solid #374151;
  padding: 2px;
  text-align: right;
  font-size: 0.9rem !important;
}

.cell_status {
  border-top: 1px solid #374151;
  border-right: 1px solid #374151;
  display: flex;
}

.cell_order_details {
  border-top: 1px solid #374151;
  border-right: 1px solid #374151;
  padding: 0px;
}

.cell_total_container {
  border-top: 1px solid #374151;
  border-right: 1px solid #374151;
  display: flex;
}

.cell_total {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 10px;
  font-size: 1.8rem;
  font-weight: bold;
  height: 60px;
  width: 100%;
}

.icons_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100px;
}

.delete_icon_holder {
  color: red;
  padding: 10px 10px 8px 10px;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.4rem;
  border: 1px solid red;
}

.delete_icon_holder:hover {
  background-color: red;
  color: beige;
}

.edit_icon_holder {
  color: blue;
  padding: 10px 10px 8px 12px;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.4rem;
  border: 1px solid blue;
}

.edit_icon_holder:hover {
  background-color: blue;
  color: beige;
}

@media (max-width: 768px) {
  .table_container {
    width: 100%;
  }

  .table_head {
    padding-left: 2px;
  }

  .table_head_text {
    font-size: 0.8rem;
    padding: 2px;
    display: flex;
    align-items: center;
    border-right: 1px solid #374151;
  }

  .cell {
    font-size: 0.9rem;
  }

  .cell_total {
    border-top: 1px solid #374151;
    text-align: right;
    padding-right: 8px;
    font-weight: 700;
    font-size: 1rem;
    width: 50px;
  }
}
