.home_container {
  background-image: url(https://wallpapercave.com/wp/wp1814934.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  color: white;
}

.title_container {
  margin-top: 100px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main_title {
  font-size: 72px;
}
