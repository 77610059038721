.footer_container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #282929;
  color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  height: 30px;
}

@media (max-width: 1080px) {
  .footer_container {
    display: none;
  }
}
