.header_bar {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #374151;
  margin-bottom: 5px;
}

.header_bar_title {
  margin-left: 15px;
  color: #374151;
}

.date_picker_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.date_picker_container_mobile {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.date_picker_columns {
  margin: 2px;
}

.grid_container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: aliceblue;
  padding: 5px;
}

.month_grid {
  display: grid;
  grid-template-columns: auto auto;
  background-color: aliceblue;
  padding: 5px;
  margin-top: 36px;
}

.day_grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  background-color: aliceblue;
  padding: 5px;
}

.year_grid {
  display: grid;
  grid-template-columns: auto;
  background-color: aliceblue;
  padding: 5px;
  margin-top: 36px;
}

.days_of_the_week {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 2px;
  padding: 5px 0px;
  border: 1px solid #374151;
  color: beige;
}

.grid {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  background-color: beige;
  border: 1px solid #374151;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.grid:hover {
  background-color: #374151;
  border: 1px solid beige;
  color: beige;
}

.grid_selected {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  background-color: #374151;
  border: 1px solid #374151;
  color: beige;
}

.button_container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0px 8px;
}

.button_cancel {
  width: 50%;
  border: 1px solid #374151;
  color: #374151;
  background-color: rgb(222, 92, 92);
  padding: 10px 10px;
  text-align: center;
  margin: 1px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.4rem;
}

.button_cancel:hover {
  background-color: #374151;
  color: rgb(222, 92, 92);
  border: 1px solid rgb(222, 92, 92);
}

.button_save {
  width: 50%;
  border: 1px solid #374151;
  color: #374151;
  background-color: rgb(90, 216, 90);
  padding: 10px 10px;
  text-align: center;
  margin: 1px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.4rem;
}

.button_save:hover {
  background-color: #374151;
  color: rgb(90, 216, 90);
  border: 1px solid rgb(90, 216, 90);
}

@media (max-width: 768px) {
  .date_picker_container {
    display: none;
  }

  .date_picker_container_mobile {
    display: flex;
  }

  .date_picker_month_year_column {
    display: flex;
    justify-content: space-between;
  }

  .month_grid {
    grid-template-columns: auto auto auto auto !important;
    width: 58%;
    margin-top: 0px;
  }

  .year_grid {
    grid-template-columns: auto auto;
    width: 30.3%;
    margin-top: 0px;
  }

  .button_container {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .days_of_the_week {
    width: 47px;
  }
  .grid {
    width: 47px;
    height: 47px;
  }

  .grid_selected {
    width: 47px;
    height: 47px;
  }
}
