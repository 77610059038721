.page_container {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow: auto;
  padding-bottom: 40px;
}

.loading_gif {
  width: 50px;
  height: 50px;
  margin-top: 100px;
}

.page_container_mobile {
  display: none;
}

.page_header {
  font-size: 2rem;
  margin-top: 5px;
}

.double {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  width: 1030px;
}
.graph_container {
  position: relative;
  border: 1px solid #374151;
  padding: 5px;
  margin: 5px auto;
}

.graph_header {
  padding: 10px;
  text-align: center;
}

.performance_0_label {
  position: absolute;
  top: 245px;
  left: 90px;
}

.performance_5k_label {
  position: absolute;
  top: 110px;
  left: 140px;
}

.performance_8k5_label {
  position: absolute;
  top: 85px;
  left: 290px;
}

.performance_11k_label {
  position: absolute;
  top: 120px;
  left: 355px;
}

.performance_13k_label {
  position: absolute;
  top: 180px;
  left: 400px;
}

.performance_15k_label {
  position: absolute;
  top: 245px;
  left: 415px;
}

.performance_value {
  position: absolute;
  top: 200px;
  left: 260px;
  font-weight: 700;
  font-size: 1.8rem;
  transform: translate(-50%, 0%);
}

@media (max-width: 1080px) {
  .page_container {
    display: none;
  }

  .page_container_mobile {
    width: 100%;
    height: calc(100vh - 125px);
    overflow-y: auto;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 60px;
  }

  .page_header {
    font-size: 1.4rem;
  }

  .graph_header {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .performance_0_label {
    position: absolute;
    top: 145px;
    left: 45px;
  }

  .performance_5k_label {
    position: absolute;
    top: 60px;
    left: 65px;
  }

  .performance_8k5_label {
    position: absolute;
    top: 43px;
    left: 180px;
  }

  .performance_11k_label {
    position: absolute;
    top: 75px;
    left: 235px;
  }

  .performance_13k_label {
    position: absolute;
    top: 110px;
    left: 260px;
  }

  .performance_15k_label {
    position: absolute;
    top: 150px;
    left: 265px;
  }

  .performance_value {
    position: absolute;
    top: 120px;
    left: 160px;
    font-weight: 700;
    font-size: 1.4rem;
    transform: translate(-50%, 0%);
  }
}
