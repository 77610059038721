.header_container {
  background-color: #282929;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  align-items: center;
  height: 70px;
}

.company_name {
  font-family: 'Kaushan Script', cursive;
  color: #fefefe;
  font-size: 36px;
}

.main_link_container {
  display: flex;
}

.main_link {
  list-style-type: none;
  text-transform: uppercase;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
}

.main_link:hover {
  cursor: pointer;
  background-color: white;
  color: #282929;
}

.main_link_active {
  list-style-type: none;
  text-transform: uppercase;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  background-color: gainsboro;
  color: #282929;
}

@media (max-width: 1024px) {
  .company_name {
    font-size: 24px;
  }
}
