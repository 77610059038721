* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: #374151;
}
