.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(55, 65, 81, 0.9);
  width: 100%;
  height: 100vh;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_wide {
  position: fixed;
  width: 100%;
  top: 10px;
  background-color: aliceblue;
  z-index: 1000;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.modal_box {
  border: 1px solid #374151;
  min-width: 50vw;
}

@media (max-width: 768px) {
  .modal_wide {
    top: 0px;
    padding: 2px 2px 5px 2px;
    height: 100vh;
  }

  .modal_box {
    border: 1px solid #374151;
    width: 100%;
    height: calc(100vh - 5px);
    overflow-y: auto;
    margin-bottom: 5px;
  }
}
